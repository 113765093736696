// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

@import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

.asterisk:after {
  content: " * ";
  color: red;
  font-weight: bold;
  min-width: 160px;
}


.alert {
  color: #e6051a;
  background-color:none;
  border-color:none;
  }

  .forgot{
    width: 1100px;
    margin-left: 170px;
  }
  .reset{
    width: 1100px;
    margin-left: 170px;
  }
  .btn{
    width: 140px;
  }

  .btn-facebook {
    color: #fff !important;
    background-color: #3b5998 !important;
    border-color: rgba(0,0,0,0.2) !important;
    font-size: 14px !important;
    margin: 10px 5px 5px 0px;
}
.btn-google {
    color: #fff !important;
    background-color: #dd4b39 !important;
    border-color: rgba(0,0,0,0.2) !important;
    font-size: 14px !important;
    margin: 10px 0px 5px 0px;
}

.btn-social {
    position: relative !important;
    padding-left: 44px !important;
    text-align: left !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.btn-social:hover {
    color: #eee !important;
}

.btn-social :first-child {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    padding: 7px !important;
    text-align: center !important;
    border-right: 1px solid rgba(0,0,0,0.2) !important;
}
.btn-social .f-icon, .btn-social .g-icon{
    width: 35px !important;
    height: 35px !important;
}
.social-child-txt{
    margin-left: -4px;
}

.custom-loader {
	display: inline-block;
	width: 18px;
	height: 18px;
	margin-right: 10px;
	vertical-align: text-bottom;
	border: 2px solid;
	border-right: 2px solid transparent;
	border-radius: 50%;
	animation: spinner-border .75s linear infinite;
}
.forgot{
  width: 1100px;
  margin-left: 450px;
}
.reset{
  width: 1100px;
  margin-left: 550px;
}

.btn-group-xs > .btn, .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
img{
  max-width:100%;
}
@media (max-width:991px){
     .mobile-logo-left-right-auto {
        margin-left: 30px !important;
        margin-right: 0px !important;
    }
    .mobile-toggle-left-right-auto {
        margin: 0 auto;
        margin-left: 0px !important;
    }
    
}
@media (max-width:576px){
  .email-template-list-mobile{
    font-size: 18px;
    margin-top: 7px !important;
  }
  .mobile-logo-left-right-auto {
        margin-left: 22px !important;
        margin-right: 0px !important;
    }
  
}
@media (max-width:350px){
  .mobile-w-100-y-apr {
    width: 100%;
    overflow-y: auto;
  }
}
